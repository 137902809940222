import { memo, useLayoutEffect, useRef } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { mq, vw, hex2Rgba, getP30, getP26_1, getP18_1, getP15, getP20, getP53, getP12_1, getP20_4, designGrid, createUnderline } from '@dy/commons/styles'

type ContentTypeText = {
  col: { from: number, expand: number},
  row: { from: number, expand: number},
  align?: {
    v: 'top' | 'center' | 'bottom',
    h: 'left' | 'center' | 'right'
  },
  color?: string,
  text: string,
  type: 'text'
}

interface IContentText {
  data: ContentTypeText
}

const contactString = [
  '/page/find-us-here',
  '/page/contactanos',
  '/page/trouvez-nous',
  '/page/finden-sie-uns-hier',
  '/page/donde-estamos',
  '/page/encontre-nos-aqui'
]

export const ContentText = memo(({ data }:IContentText) => {
  const { text, color, align } = data
  const { asPath } = useRouter()
  const ref = useRef<HTMLDivElement>()

  // This chunk allows inner embed scripts to run
  useLayoutEffect(() => {
    const range = document.createRange()
    range.selectNode(ref.current)
    const documentFragment = range.createContextualFragment(text)

    ref.current.innerHTML = ''
    ref.current.append(documentFragment)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TextContent ref={ref} className={`text-wrapper ${contactString.indexOf(asPath)  !== -1 ? 'contact' : ''}`} color={color} align={align} dangerouslySetInnerHTML={{ __html: text }} />
  )
})



const TextContent = styled.div<any>`
  font-family: ${({ theme }) => theme.fonts.tertiaryFont};
  color: ${({ color, theme }) => color ? color : theme.colors.darkred};
  text-align: ${({ align }) => align && align.h ? align.h : 'left'};
  word-break: break-word;
  width: 100%;

  a {
    ${createUnderline({ height: 1, reverse: true })}
  }

  h2, h3, h4,
  p, ul, ol, dl,
  q, a {
    color: inherit;
    text-align: inherit;
    word-break: inherit;
  }

  p, ul, ol, dl, q, a {
    color: ${({ theme }) => theme.colors.darkred};
  }

  h2, h3, h4, h5, h6 {
    color: ${({ theme }) => theme.colors.red};
  }

  h2, h3, h4 {
    font-weight: 500;
    margin-bottom: ${vw(16, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(16, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 20px;
    }

    + p {
      margin-top: -${vw(10, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        margin-top: -${vw(10, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        margin-top: -${vw(10, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin-top: -10px;
      }
    }
  }

  h1, h2 {
    ${getP30()}

    br {
      + p, ul, ol, dl, h2, h3, h4, h5, h6, a {
        margin-top:  ${vw(15, 'mobile')};

        ${mq.greaterThan('nexus7')} {
          margin-top: ${vw(15, 'nexus7')};
        }

        ${mq.greaterThan('tablet')} {
          margin-top: ${vw(20, 'desktop')};
        }

        ${mq.greaterThan('desktop')} {
          margin-top: 20px;
        }
      }
    }
  }

  h3 {
    ${getP26_1()}

    br {
      + p, ul, ol, dl, h2, h3, h4, h5, h6, a {
        margin-top:  ${vw(15, 'mobile')};

        ${mq.greaterThan('nexus7')} {
          margin-top: ${vw(15, 'nexus7')};
        }

        ${mq.greaterThan('tablet')} {
          margin-top: ${vw(15, 'desktop')};
        }

        ${mq.greaterThan('desktop')} {
          margin-top: 15px;
        }
      }
    }
  }

  h4 {
    ${getP18_1()}
  }

  h5 {
    ${getP15()}
  }

  h4, h5 {
    br {
      + p, ul, ol, dl, h2, h3, h4, h5, h6, a {
        margin-top:  ${vw(10, 'mobile')};

        ${mq.greaterThan('nexus7')} {
          margin-top: ${vw(10, 'nexus7')};
        }

        ${mq.greaterThan('tablet')} {
          margin-top: ${vw(10, 'desktop')};
        }

        ${mq.greaterThan('desktop')} {
          margin-top: 10px;
        }
      }
    }
  }

  h6 {
    ${getP12_1()}

    br {
      + p, ul, ol, dl, h2, h3, h4, h5, h6, a {
        margin-top:  ${vw(8, 'mobile')};

        ${mq.greaterThan('nexus7')} {
          margin-top: ${vw(8, 'nexus7')};
        }

        ${mq.greaterThan('tablet')} {
          margin-top: ${vw(8, 'desktop')};
        }

        ${mq.greaterThan('desktop')} {
          margin-top: 8px;
        }
      }
    }
  }

  p {
    ${getP18_1()}

    br {
      content: '';
      display: block;
      margin-bottom:  ${vw(10, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        margin-bottom: ${vw(10, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        margin-bottom: ${vw(15, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin-bottom: 15px;
      }
    }
  }

  ul, ol, dl {
    ${getP20()}
    font-weight: normal;
    margin-bottom: ${vw(48, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(48, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(60, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 60px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    br {
      + p, ul, ol, dl, h2, h3, h4, h5, h6, a {
        margin-top:  ${vw(10, 'mobile')};

        ${mq.greaterThan('nexus7')} {
          margin-top: ${vw(10, 'nexus7')};
        }

        ${mq.greaterThan('tablet')} {
          margin-top: ${vw(15, 'desktop')};
        }

        ${mq.greaterThan('desktop')} {
          margin-top: 15px;
        }
      }
    }
  }




  br {
    + p, ul, ol, dl, h2, h3, h4, h5, h6, a {
      ${getP20_4()}
    }
  }

  ul, ol {
    padding-left: 18px;
  }

  ul {
    li {
      list-style-type: disc;
    }
  }

  ol {
    li {
      list-style-type: decimal;
    }
  }

  q, blockquote {
    border-left: 0;
    color: ${({ theme }) => theme.colors.red};
    ${getP53()}
    display: block;
    font-weight: bold;
    font-style: normal;
    margin: auto;
    padding: ${vw(12, 'mobile')} ${vw(20, 'mobile')} 0;
    position: relative;
    text-align: center;
    width: 100%;

    ${mq.greaterThan('nexus7')} {
      padding: ${vw(12, 'nexus7')} ${vw(20, 'nexus7')} 0;
    }

    ${mq.greaterThan('tablet')} {
      padding: ${vw(20, 'desktop')} 0 ${vw(20, 'desktop')};
      transform: translateX(-${vw(120, 'desktop')});
      width: calc(100% + ${vw(120 * 2, 'desktop')});
    }

    ${mq.greaterThan('desktop')} {
      padding: 20px 0 20px;
      transform: translateX(-120px);
      width: calc(100% + 240px);
    }

    &:before,
    &:after {
      content: '';
      background-image: url('/images/svg/i--double-quotes.svg');
      background-repeat: no-repeat;
      display: inline-block;
      height: ${vw(33, 'mobile')};
      opacity: .2;
      position: absolute;
      user-select: none;
      width: ${vw(47, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        height: ${vw(33, 'nexus7')};
        width: ${vw(47, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        height: ${vw(66, 'desktop')};
        width: ${vw(94, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        height: 66px;
        width: 94px;
      }
    }

    &:before {
      left: ${vw(20, 'mobile')};
      top: -${vw(10, 'mobile')};
      transform: rotate(180deg);

      ${mq.greaterThan('nexus7')} {
        left: ${vw(20, 'nexus7')};
        top: -${vw(10, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        left: ${vw(30, 'desktop')};
        top: -${vw(10, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        left: 30px;
        top: -10px;
      }
    }

    &:after {
      bottom: -${vw(10, 'mobile')};
      right: ${vw(20, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        bottom: -${vw(10, 'nexus7')};
        right: ${vw(30, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        bottom: 0;
        right: ${vw(30, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        right: 30px;
      }
    }

    > p {
      color: ${({ theme }) => theme.colors.red};
      display: inline;
      ${getP53()}
      font-family: ${({ theme }) => theme.fonts.primaryFont};
      font-style: normal;
      text-align: center;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;

    th,
    td {
      ${getP20()}
      padding: ${vw(20, 'mobile')} ${vw(5, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        padding: ${vw(20, 'nexus7')} ${vw(5, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        padding: ${vw(30.5, 'desktop')} ${vw(10, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        padding: 30.5px 10px;
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    th {
      color: ${({ theme }) => theme.colors.text};
      border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.text, .6)};
    }

    tbody, tfoot {
      td {
        border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.text, .1)};
        color: ${({ theme }) => hex2Rgba(theme.colors.text, .6)};

        ${mq.greaterThan('tablet')} {
          max-width: ${vw(490, 'desktop')};
          vertical-align: initial;
        }

        ${mq.greaterThan('desktop')} {
          max-width: 490px;
        }
      }

      tr {
        &:last-child {
          td {
            border-color: ${({ theme }) => hex2Rgba(theme.colors.text, 0)};
          }
        }
      }
    }
  }

  &.contact {
    ${designGrid({})}
    align-items: baseline;
    padding: 0;
    margin-top: ${vw(60, 'mobile')};

    &:first-child {
      margin-top: 0;
    }
    p:first-of-type {
      display: none;
    }

    h3, b {
      ${getP26_1()}
    }
    > section {
      margin-bottom: 0;
    }

    h3 {
      grid-area: 1 / 1 / span 1 / span 1;
      text-align: center;
      font-size: 1rem;
      ${mq.greaterThan('tablet')}{
        text-align: left;
      }
    }
    h4.spacer_contact {
      grid-area:2 / 1 / span 1 / span 1 !important;
    }
    p {
      display: inline-grid;
      ${mq.lessThan('tablet')}{
        display: inline-grid;
        grid-column: 2 / span 5;
        padding: 0 ${vw(20, 414)}vw 0 ${vw(13, 'mobile')};
      }

      &.nop {
        display: none;
      }
    }

    span {
      font-size: ${vw(15, 'mobile')};
      line-height: ${vw(20, 'mobile')};
      letter-spacing: -.5px;
      font-family: ${({ theme }) => theme.fonts.basier};
      font-weight: 400;

      ${mq.greaterThan('tablet')}{
        font-size: ${vw(18, 'desktop')};
        line-height: ${vw(25, 'desktop')};
      }
    }

    p {
      margin-bottom: ${vw(37, 'mobile')};

      b, span {
        display: block;
        margin-bottom: ${vw(15, 'mobile')};

        ${mq.greaterThan('tablet')}{
          margin-bottom: ${vw(15, 'desktop')};
        }
      }
    }

    ${mq.greaterThan('tablet')}{
      margin-top: ${vw(60, 'desktop')};

      h3 {
        font-size: unset;
        grid-area: 1 / 2 / span 1 / span 2;
      }
      h4 {
        grid-area:2 / 1 / span 1 / span 1 !important;
      }
      p {
        display: inline-grid;
        grid-column-end: span 3;
        margin-bottom: ${vw(60, 'desktop')};
        align-items: end;

        &.nop {
          display: block;
          opacity: 0;
          grid-column-end: span 2;
        }
      }
    }
  }
`
