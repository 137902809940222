/* eslint-disable indent */
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { useMutation } from '@apollo/client'
import { CUSTOMER_DELETE_FROM_CART } from '@/api'
import { Figure, Price } from '@dy/commons/components'
import { AddToCart } from '.'
import { MODALS } from '@dy/commons/context'
import { useCart, useModal } from '@dy/commons/hooks'
import { useTranslator } from '@/hooks'
import { cookie } from '@dy/commons/utils'
import styled from 'styled-components'
import { mq, vw, createUnderline, getP18_1, getP15_1B, getP12_1, hex2Rgba, getP10_1, getP15_3 } from '@dy/commons/styles'
import type { TypeCartLine } from '@dy/commons/schema'
import { getDynamicText, getDayOfWeekName, getLang, getDateFormatted, groupByDate, currencyFormatter } from '@dy/commons/utils'
const DynamicModalStockCart = dynamic(() => import('@/components').then(mod => mod.ModalStockCart))

const mediaThumbnailProductSize = `(min-width: 768px) ${vw(100, 'desktop')}, ${vw(30, 'mobile')}`
const getLabels = labels => labels.map((label, idx) => <p key={idx} className='on_demand_label'>{label.name}</p>)

const formatLabels = (labels, deliveryType, onDemandText, discount) => {
  let allLabels = []
  if(labels) allLabels = [...labels]
  if(deliveryType === 'on_demand') allLabels.push({name: onDemandText})
  if(discount > 0) allLabels.push({name: `${discount}%`})
  return allLabels
}

const getCartLines = (data: TypeCartLine[], deleteCartLine: (variantId: any) => Promise<void>, locale: string, buttonText: string, onDemandText: string, t, multipleDeliveryDates: boolean, cartDeliveryDate:any) => {
  const groupByDateData = groupByDate(data)
  return groupByDateData.map((cartLines, idx) => (
    <div key={idx}>
      <dt>
        { multipleDeliveryDates &&
          <SpanDeliveryDate className='opacity'>
            {getDynamicText(t('cart.delivery_available') as string, {
              deliveryDate: getDateFormatted(getLang(locale), cartLines[0]?.variant.deliveryDate),
              onDemandDay: getDayOfWeekName(getLang(locale), cartLines[0]?.variant.onDemandDay),
              cartDeliveryDate: getDateFormatted(getLang(locale), cartDeliveryDate)
            })}
          </SpanDeliveryDate>
        }
      </dt>
      {cartLines.map((item, idx) => {
        const { product, variant, quantity } = item
        const { name, brand, labels, inventoryTracking } = product
        const { media, price, stock, deliveryType } = variant
        const { discount } = price || { }
        const test = formatLabels(labels, deliveryType, onDemandText, discount)
        const LabelItems = getLabels(test)
        const priceQuantity = price?.salePrice ? { salePrice: price.salePrice * quantity, retailPrice: price.retailPrice * quantity  } : null
        const priceUnit = price?.salePrice ? { salePrice: price.salePrice, retailPrice: price.retailPrice  } : null
        return (
          <Dd key={`${idx}-${'variantId'}`}>
            {media?.[0]?.medium ? <Figure media={media?.[0].medium} quality={50} sizes={mediaThumbnailProductSize} /> : <figure className='placeholder'></figure>}
            <div className={'info'}>
              {LabelItems.length > 0  && <div className='labels'>
                {LabelItems}
                </div>}
              <span>{name}</span>
              <span>{brand?.name}</span>
              {priceUnit && <span>{currencyFormatter({ price: priceUnit.salePrice, localeRegion: locale, round: false, trailingZeros: true })}/u</span>}
            </div>
            <div className='counter-wrapper'>
              <AddToCart data={item as TypeCartLine} stock={stock} isPDP={false} isCart={true} notAvailable={price === null && stock > 0} inventoryTracking={inventoryTracking} />
            </div>
            <div className='price_wrapper'>
              {/* { !displayStockWarning && <Price type='cart' price={retailPrice:{exTax:fullPrice }} displayRetailPrice={true} round={false}></Price>} */}
              <Price type='cart' price={priceQuantity} displayRetailPrice={false} round={false} pricePer='' />
              <button onClick={() => { deleteCartLine(variant.id) }}>{buttonText}</button>
            </div>
          </Dd>
        )
      })}
    </div>
  ))
}


export const CartList = memo(() => {
  const { locale } = useRouter()
  const { t } = useTranslator()
  const { deleteFromCart, bulkUpdateCart, multipleDeliveryDates, cartLines, deliveryDate:cartDeliveryDate } = useCart()
  const { toggle:toggleModalStock } = useModal(MODALS.STOCK_CART)
  const [cartLinesDelete, { data:dataAfterDelete }] = useMutation(CUSTOMER_DELETE_FROM_CART)
  const [productsToDelete, setProductsToDelete] = useState([])

  const deleteCartLine = useCallback(async (variantId) => {
    deleteFromCart(variantId)
    const DYCustomerToken = cookie.get('DY_CUSTOMER_TOKEN')

    try {
      cartLinesDelete({
        variables: {
          variantId
        },
        context: {
          isPrivatePath: true,
          DYCustomerToken
        }
      })
    } catch (e) {
      console.error(e)
    }
  }, [deleteFromCart, cartLinesDelete])

  const CartLinesGroupedByDate = useMemo(() => getCartLines(cartLines, deleteCartLine, locale, t('commons.actions.delete') as string, t('product_page.product.demand_product') as string, t, multipleDeliveryDates, cartDeliveryDate), [cartLines, deleteCartLine, locale, multipleDeliveryDates, t, cartDeliveryDate])

  useEffect(()=>{
    cartLines.forEach(element => {
      const { product, variant, quantity } = element
      const { stock } = variant
      const displayStockWarning = product.inventoryTracking && quantity > stock
      if(displayStockWarning) {
        setProductsToDelete(prevValue => [...prevValue, product.name])
        toggleModalStock()
        setTimeout(()=>{
          deleteCartLine(variant.id)
        }, 2000)
      }

    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartLines])

  useEffect(() => {
    if (!dataAfterDelete) return
    let cartRes = dataAfterDelete?.cartLinesDelete?.cart

    console.log('📀📀 CMS cartRes dataAfterDelete', cartRes)
    if (!!cartRes && typeof cartRes === 'object') {
      const { cartLines, bigcommerceId: cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount } = cartRes
      console.log('📀📀 CMS cartRes', multipleDeliveryDates)
      bulkUpdateCart(cartLines, cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAfterDelete])

  return (
    <Section>
      <DynamicModalStockCart productsToDelete={productsToDelete}/>
      <dl>
        {CartLinesGroupedByDate}
      </dl>
    </Section>
  )
})

const Section = styled.section`
  display: grid;
  grid-column: 1 / span 6;
  height: fit-content;
  margin: ${vw(20, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    margin: ${vw(20, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    grid-column: 2 / span 6;
    margin: 0;
    min-height: 200px;
    overflow: hidden;
  }

  dt {
    color: ${({ theme }) => theme.colors.darkred};
    margin: ${vw(50, 'mobile')} 0 ${vw(8, 'mobile')} ${vw(20, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin: ${vw(50, 'tablet')} 0 ${vw(8, 'tablet')} ${vw(20, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      margin: ${vw(40, 'desktop')} 0 ${vw(8, 'desktop')} ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin: 40px 0 8px 20px;
    }
  }

  dl {
    > div {
      &:first-child {
        > dt {
          margin-top: 0;
        }
      }
    }
  }
`

const Dd = styled.dd`
  align-items: start;
  background-color: white;
  column-gap: ${vw(10, 'mobile')};
  display: grid;
  grid: auto-flow / repeat(6,1fr);
  min-height: ${vw(90, 'mobile')};
  padding: ${vw(20, 'mobile')};
  position: relative;

  ${mq.greaterThan('nexus7')} {
    column-gap: ${vw(10, 'nexus7')};
    padding: ${vw(20, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    column-gap: ${vw(10, 'desktop')};
    min-height: ${vw(156, 'desktop')};
    padding:${vw(20, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    column-gap: 10px;
    min-height: 100px;
    padding: 20px;
  }

  &:first-of-type {
    border-top-left-radius: ${vw(20, 'mobile')};
    border-top-right-radius: ${vw(20, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      border-top-left-radius: ${vw(20, 'tablet')};
      border-top-right-radius: ${vw(20, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      border-top-left-radius: ${vw(20, 'desktop')};
      border-top-right-radius: ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
  }

  &:last-of-type {
    border-bottom-left-radius: ${vw(20, 'mobile')};
    border-bottom-right-radius: ${vw(20, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      border-bottom-left-radius: ${vw(20, 'tablet')};
      border-bottom-right-radius: ${vw(20, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      border-bottom-left-radius: ${vw(20, 'desktop')};
      border-bottom-right-radius: ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.darkred, .1)};
  }

  .price_wrapper {
    grid-area: 2 / 5 / span 1 / span 2;
    justify-self: flex-end;
    text-align: right;
    width: ${vw(150, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      width: ${vw(150, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      grid-area: 1 / 6 / span 1 / span 1;
      width: ${vw(95, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      width: 95px;
    }

    div:first-child {
      padding-right: 0;
      padding-bottom: 0;
    }

    button:last-child {
      ${getP12_1()}
      ${createUnderline({ color: 'darkcreme' })}
      color: ${({ theme }) => theme.colors.darkcreme};
      margin-top: ${vw(10, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        margin-top: ${vw(10, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        margin-top: ${vw(10, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin-top: 10px;
      }
    }
    .sale {
      >span {
        ${getP15_3()}
        color: ${({ theme }) => theme.colors.darkcreme};

      }
    }
  }

  .counter-wrapper {
    grid-area: 2 / 3 / span 1 / span 3;
    height: 100%;
    position: relative;

    ${mq.greaterThan('tablet')} {
      grid-area: 1 / 4 / span 1 / span 2;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    grid-area: 1 / 3 / span 1 / span 4;
    min-height: ${vw(156, 'mobile')};

    ${mq.greaterThan('tablet')} {
      grid-area: 1 / 2 / span 1 / span 3;
      min-height: ${vw(100, 'desktop')};
    }
    ${mq.greaterThan('desktop')} {
      min-height:80px;
    }

   span {
      ${getP15_1B()}
      color: ${({ theme }) => theme.colors.red};
      display: block;
    }

    span:first-child:not(.label){
      ${getP18_1()}
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    > div {

      margin-top: ${vw(10, 'mobile')};
      position: unset;
      top: unset;

      ${mq.greaterThan('nexus7')} {
        margin-top: ${vw(10, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        margin-top: ${vw(10, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin-top: 10px;
      }
      > span:not(.on_demand) {
        background-color: ${({ theme }) => hex2Rgba(theme.colors.red, .2)};
      }
      .on_demand {
        ${getP15_1B()}
        color: ${({ theme }) => theme.colors.orange};
        display: block;
      }




    }

    .on_demand_label {
      ${getP12_1()}
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.darkcreme};
      text-transform: uppercase;
      border-radius: ${vw(12, 'mobile')};
      padding: ${vw(5, 'mobile')} ${vw(9, 'mobile')};
      max-height: ${vw(25, 'mobile')};
      display: inline-block;
      max-width: ${vw(97, 'mobile')};
      margin-bottom:  ${vw(10, 'mobile')};
      margin-right:  ${vw(10, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        max-width: ${vw(97, 'nexus7')};
        margin-bottom:  ${vw(10, 'nexus7')};
        margin-right:  ${vw(10, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        max-width: ${vw(110, 'desktop')};
        margin-bottom:  ${vw(10, 'desktop')} ;
        margin-right:  ${vw(10, 'desktop')} ;
      }

      ${mq.greaterThan('desktop')} {
        max-width: 110px;
        margin-bottom:10px ;
        margin-right:10px ;

      }

      ${mq.greaterThan('nexus7')} {
        border-radius: ${vw(12, 'nexus7')};
        padding: ${vw(5, 'nexus7')} ${vw(9, 'nexus7')};
        max-height: ${vw(25, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        border-radius: ${vw(12, 'desktop')};
        padding: ${vw(5, 'desktop')} ${vw(9, 'desktop')};
        max-height: ${vw(25, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        border-radius: 12px;
        padding: 5px 9px;
        max-height: 25px;
      }
    }

  }

  figure {
    height: ${vw(100, 'mobile')};
    grid-area: 1 / 1 / span 1/ span 2;
    width: ${vw(100, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      height: ${vw(100, 'nexus7')};
      width: ${vw(100, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(100, 'desktop')};
      grid-area: 1/ 1 / span 1 / span 1;
      width: ${vw(100, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 100px;
      width: 100px;
    }

    &.placeholder {
      background-color: rgba(0, 0, 0, .15);
    }
  }

`

const SpanDeliveryDate = styled.span`
  ${getP10_1()}
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  color: ${({ theme }) => theme.colors.red};
  grid-area: 3 / 1 / span 1 / span 6;
  margin-top: ${vw(10, 'mobile')};
  opacity: .5;

  ${mq.greaterThan('tablet')} {
    grid-area: 2 / 2 / span 1 / span 6;
    margin-top: ${vw(10, 'desktop')};
  }
`
